import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import PATH from '@assets/routes/routes.json';
import { environment } from '@environments/environment';
import { Settings } from '@models/settings';
import { AccountService } from '@services/account.service';
import { Subject } from 'rxjs';

@Injectable()
export class SettingsService {
	isFooterVisible: boolean;
	footerVisibilityChange: Subject<boolean> = new Subject<boolean>();

	private readonly indexLandingPage_Label: number = 0;
	private readonly indexLandingPage_Alias: number = 1;
	private readonly indexLandingPage_Route: number = 2;

	private readonly LandingPage_Home: string[] = ['Home', 'lpHome', PATH.HOME]; // private readonly LandingPage_Home: string[] = ['Home', 'lpHome', 'home'];
	private readonly LandingPage_OnlineFahrzeuge: string[] = ['Online - Fahrzeuge', 'lpOnlineFahrzeuge', PATH.ONLINE_FAHRZEUGE]; // private readonly LandingPage_OnlineFahrzeuge: string[] = ['Online - Fahrzeuge', 'lpOnlineFahrzeuge', 'online/karte'];
	private readonly LandingPage_OnlineLeerungen: string[] = ['Online - Leerungen', 'lpOnlineLeerungen', PATH.ONLINE_LEERUNGEN]; // private readonly LandingPage_OnlineLeerungen: string[] = ['Online - Leerungen', 'lpOnlineLeerungen', 'online/leerung'];
	private readonly LandingPage_StammdatenFahrzeuge: string[] = ['Stammdaten - Fahrzeuge', 'lpStammdatenFahrzeuge', PATH.FAHRZEUG + '/' + PATH.LIST]; // private readonly LandingPage_StammdatenFahrzeuge: string[] = ['Stammdaten - Fahrzeuge', 'lpStammdatenFahrzeuge', 'fahrzeug/list'];
	private readonly LandingPage_RueckfahrkatasterListe: string[] = ['Rückfahrkataster', 'lpRasListe', PATH.RK_STRECKE + '/' + PATH.LIST]; // private readonly LandingPage_RueckfahrkatasterListe: string[] = ['Rückfahrkataster', 'lpRasListe', 'rueckfahrkataster_aufzeichnung/list'];
	private readonly LandingPage_BerichteLeerungen: string[] = ['Berichte - Leerungen', 'lpBerichteLeerungen', PATH.LEERUNG + '/' + PATH.LIST]; // private readonly LandingPage_BerichteLeerungen: string[] = ['Berichte - Leerungen', 'lpBerichteLeerungen', 'leerung/list'];
	private readonly LandingPage_BerichteFahrtbericht: string[] = ['Berichte - Fahrtberichte', 'lpBerichteFahrtberichte', PATH.FAHRTEBRICHT + '/' + PATH.LIST]; // private readonly LandingPage_BerichteFahrtbericht: string[] = ['Berichte - Fahrtberichte', 'lpBerichteFahrtberichte', 'fahrtbericht/list'];

	constructor(
		private accountService: AccountService,
		private http: HttpClient
	) {
		this.footerVisibilityChange.subscribe(value => {
			this.isFooterVisible = value;
		});
	}

	/**
	 * Einstellung bearbeitung
	 * 
	 * @param setting Einstellung die geändert werden soll
	 * @returns Antwort der API
	 */
	editSetting(setting): Promise<any> {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/settings/edit`, setting, options).toPromise());
		});
	}

	/**
	 * Einstellungen abfragen
	 * 
	 * @returns Antwort der API
	 */
	getSettings(): Promise<Settings> {
		return new Promise<Settings>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/settings`, options).toPromise());
		});
	}

	/**
	 * Footer ein-/ausblenden
	 */
	toggleFooterVisibility(): void {
		this.footerVisibilityChange.next(!this.isFooterVisible);
	}

	getPageSelection(): any[] {
		return [
			{ label: this.LandingPage_Home[this.indexLandingPage_Label], value: this.LandingPage_Home[this.indexLandingPage_Alias] },
			{ label: this.LandingPage_OnlineFahrzeuge[this.indexLandingPage_Label], value: this.LandingPage_OnlineFahrzeuge[this.indexLandingPage_Alias] },
			{ label: this.LandingPage_OnlineLeerungen[this.indexLandingPage_Label], value: this.LandingPage_OnlineLeerungen[this.indexLandingPage_Alias] },
			{ label: this.LandingPage_StammdatenFahrzeuge[this.indexLandingPage_Label], value: this.LandingPage_StammdatenFahrzeuge[this.indexLandingPage_Alias] },
			{ label: this.LandingPage_RueckfahrkatasterListe[this.indexLandingPage_Label], value: this.LandingPage_RueckfahrkatasterListe[this.indexLandingPage_Alias] },
			{ label: this.LandingPage_BerichteLeerungen[this.indexLandingPage_Label], value: this.LandingPage_BerichteLeerungen[this.indexLandingPage_Alias] },
			{ label: this.LandingPage_BerichteFahrtbericht[this.indexLandingPage_Label], value: this.LandingPage_BerichteFahrtbericht[this.indexLandingPage_Alias] }
		];
	}

	getRouteForLandingPageAlias(value: string): string {
		let resultRoute: string = this.LandingPage_Home[this.indexLandingPage_Route]; // failback/default

		if (value !== null && value !== undefined && value.trim().length > 0) {
			value = value.trim();

			if (value === this.LandingPage_Home[this.indexLandingPage_Alias]) {
				resultRoute = this.LandingPage_Home[this.indexLandingPage_Route];
			} else if (value === this.LandingPage_OnlineFahrzeuge[this.indexLandingPage_Alias]) {
				resultRoute = this.LandingPage_OnlineFahrzeuge[this.indexLandingPage_Route];
			} else if (value === this.LandingPage_OnlineLeerungen[this.indexLandingPage_Alias]) {
				resultRoute = this.LandingPage_OnlineLeerungen[this.indexLandingPage_Route];
			} else if (value === this.LandingPage_StammdatenFahrzeuge[this.indexLandingPage_Alias]) {
				resultRoute = this.LandingPage_StammdatenFahrzeuge[this.indexLandingPage_Route];
			} else if (value === this.LandingPage_RueckfahrkatasterListe[this.indexLandingPage_Alias]) {
				resultRoute = this.LandingPage_RueckfahrkatasterListe[this.indexLandingPage_Route];
			} else if (value === this.LandingPage_BerichteLeerungen[this.indexLandingPage_Alias]) {
				resultRoute = this.LandingPage_BerichteLeerungen[this.indexLandingPage_Route];
			} else if (value === this.LandingPage_BerichteFahrtbericht[this.indexLandingPage_Alias]) {
				resultRoute = this.LandingPage_BerichteFahrtbericht[this.indexLandingPage_Route];
			} // ... to be continued
		}

		return resultRoute;
	}
}
