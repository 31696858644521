import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { ListComponent } from '@app/common/templates/list/list.component';
import { Module } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { ExportService } from '@app/services/export.service';
import { SettingsService } from '@app/services/settings.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
	templateUrl: '../../../../common/templates/list/list.component.html',
	styleUrls: ['../style.scss', '../../../../common/templates/list/style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class TblRueckfahrkatasterAufzeichnungListComponent extends ListComponent {
	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public changeDetectorRef: ChangeDetectorRef,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public elRef: ElementRef,
		public exportService: ExportService,
		public globals: Globals,
		public messageService: MessageService,
		public router: Router,
		public settingsService: SettingsService,
		public translate: TranslateService
	) {
		super(accountService, breadcrumbService, changeDetectorRef, confirmationService, crudService, elRef, exportService, globals, messageService, router, settingsService, translate);

		this.apiUrl = 'TblRueckfahrkatasterAufzeichnung';
		this.name = 'MENU.AUFZEICHNUNG';
		this.url = '/' + PATH.RK_AUFZEICHNUNG;
		this.module = Module.ReversingCadastral;

		this.possibleCols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'ankey', required: true, width: 400 },
			{ type: 'date', key: 'aufgezeichnet', required: true, width: 400 },
			{ type: 'text', key: 'name', required: true, width: 400 },
			{ type: 'numeric', key: 'plz_von', required: false, width: 400 },
			{ type: 'text', key: 'ort_von', required: false, width: 400 },
			{ type: 'text', key: 'strasse_von', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_von', required: false, width: 400 },
			{ type: 'numeric', key: 'plz_bis', required: false, width: 400 },
			{ type: 'text', key: 'ort_bis', required: false, width: 400 },
			{ type: 'text', key: 'strasse_bis', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_bis', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_kennzeichen', required: true, width: 400 },
			{ type: 'boolean', key: 'in_kartaster', required: true, width: 400 },
			{ type: 'numeric', key: 'fkey', required: true, width: 400 },
			{ type: 'rating', key: 'bewertung', required: true, width: 400 },
			{ type: 'numeric', key: 'laenge', required: true, width: 400 },
			{ type: 'boolean', key: 'breiter_350', required: true, width: 400 },
			{ type: 'boolean', key: 'kuerzer_150', required: true, width: 400 },
			{ type: 'boolean', key: 'ras_deaktiviert', required: true, width: 400 },
			{ type: 'text', key: 'freitext', required: true, width: 400 },
			{ type: 'text', key: 'gpx', required: false, width: 400 },
			{ type: 'text', key: 'video_url', required: false, width: 400 },
			{ type: 'text', key: 'rk_gruende', required: false, width: 400 },
			{ type: 'text', key: 'rk_gefahren', required: false, width: 400 },
			{ type: 'text', key: 'rk_massnahmen', required: false, width: 400 },
			{ type: 'text', key: 'rk_status', required: false, width: 400 }
		];
		this.cols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'date', key: 'aufgezeichnet', required: true, width: 400 },
			{ type: 'text', key: 'name', required: true, width: 400 },
			{ type: 'numeric', key: 'plz_von', required: false, width: 400 },
			{ type: 'text', key: 'ort_von', required: false, width: 400 },
			{ type: 'text', key: 'strasse_von', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_von', required: false, width: 400 },
			{ type: 'numeric', key: 'plz_bis', required: false, width: 400 },
			{ type: 'text', key: 'ort_bis', required: false, width: 400 },
			{ type: 'text', key: 'strasse_bis', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_bis', required: false, width: 400 },
			{ type: 'text', key: 'rk_status', required: false, width: 400 },
			{ type: 'numeric', key: 'laenge', required: true, width: 400 },
			{ type: 'boolean', key: 'breiter_350', required: true, width: 400 },
			{ type: 'boolean', key: 'kuerzer_150', required: true, width: 400 },
			{ type: 'text', key: 'FREMD_kennzeichen', required: true, width: 400 },
			{ type: 'text', key: 'freitext', required: true, width: 400 }
		];

		this.breadcrumbService.setItems([
			{ label: 'MENU.RUECKFAHRKATASTER' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.LIST', routerLink: [this.url + '/' + PATH.LIST] }
		]);
	}
}
