import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Module } from '@app/models/permission';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { ConnectionSetting } from '@models/admin';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from '@services/admin.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
	templateUrl: './detail.component.html',
	styleUrls: ['../style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class AdminVisyConnectionSettingsDetailComponent implements OnInit {
	basefields: any[];
	connectionFlags: any[];
	connectionSetting: ConnectionSetting;
	fieldgroups: any[];
	id: string;
	loading = false;
	permissions: any[];

	constructor(
		private adminService: AdminService,
		private breadcrumbService: BreadcrumbService,
		private confirmationService: ConfirmationService,
		private messageService: MessageService,
		private router: Router,
		public translate: TranslateService,
	) {
		const href = this.router.url.split('/');
		this.id = href[href.length - 1];

		this.breadcrumbService.setItems([
			{ label: 'MENU.ADMIN' },
			{ label: 'MENU.CONNECTION_SETTINGS', routerLink: ['/' + PATH.CONNECTION_SETTINGS] },
			{ label: 'BREADCRUMBS.DETAIL', routerLink: ['/' + PATH.CONNECTION_SETTINGS + '/' + PATH.DETAIL + this.id] }
		]);

		this.basefields = [
			{ type: 'number', key: 'Id', label: 'Id' },
			{ type: 'string', key: 'SettingsName', label: 'Name' },
			{ type: 'string', key: 'Guid', label: 'Guid' },
			{ type: 'string', key: 'ConnectionStringBusinessData', label: 'Business Data' },
			{ type: 'string', key: 'ConnectionStringTblOnline', label: 'TblOnline' },
			{ type: 'string', key: 'ConnectionStringMaptour', label: 'Maptour' },
			{ type: 'string', key: 'ConnectionStringServiceData', label: 'Service Data' },
			{ type: 'string', key: 'MandantId', label: 'Mandant Id' },
			{ type: 'number', key: 'BookedUsers', label: 'Gebuchte Accounts' },
			{ type: 'number', key: 'UsedUsers', label: 'Genutzte Accounts' },
		];

		this.connectionFlags = [
			{ bit: 0, label: '', set: 'TblOnline', unset: 'Maptour' },
			{ bit: 1, label: '', set: 'AtosV3', unset: 'vionDB' },
		];

		this.permissions = [
			{ key: Module.OnlineCollections, label: 'Online Leerungen' },
			{ key: Module.OnlineVehicles, label: 'Online Fahrzeuge' },
			{ key: Module.BHV, label: 'BHV' },
			{ key: Module.ReversingCadastral, label: 'Rückfahrkataster' },
			{ key: Module.Masterdata, label: 'Stammdaten' },
		];

		this.fieldgroups = [
			this.basefields,
		];
	}

	ngOnInit(): void {
		this.getConnectionSetting();
	}

	delete(): void {
		this.confirmationService.confirm({
			message: this.translate.instant('CONFIRMATION.DELETE_QUESTION'),
			header: this.translate.instant('CONFIRMATION.CONFIRM'),
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: this.translate.instant('CONFIRMATION.YES'),
			rejectLabel: this.translate.instant('CONFIRMATION.NO'),
			accept: () => {
				this.loading = true;
				this.adminService.deleteConnectionSetting(this.connectionSetting.Id).then(res => {
					this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.DELETED'), life: 3000 });
					this.router.navigate([PATH.CONNECTION_SETTINGS + '/' + PATH.LIST]);
				}).catch(err => {
					err.error.forEach(e => {
						if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
						} else {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
						}
					})
					this.loading = false;
				});
			}
		});
	}

	edit(): void {
		this.router.navigate(['/' + PATH.CONNECTION_SETTINGS + '/' + PATH.EDIT + '/' + this.id]);
	}

	getConnectionSetting(): void {
		this.loading = true;
		this.adminService.getConnectionSetting(+this.id).then(res => {
			this.connectionSetting = res;
			if (this.connectionSetting.ConnectionFlags == null) {
				this.connectionSetting.ConnectionFlags = 0;
			}
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading = false;
		});
	}

	isBookedModule(module: Module): boolean {
		return (this.connectionSetting.BookedModules & Math.pow(2, module)) == Math.pow(2, module);
	}

	isFlagSet(flag: number): boolean {
		return (this.connectionSetting.ConnectionFlags & Math.pow(2, flag)) == Math.pow(2, flag);
	}
}
