<!-- Common stuff like toasts and loading indicator -->
<common-list-view-elements [loading]="loading" [accountService]="accountService" [parent]="this">
</common-list-view-elements>

<!-- ui Canvas -->
<canvas hidden #emptyCanvas></canvas>

<!-- Context Menu -->
<p-contextMenu #cm [model]="isAufzeichnungSelected() ? contextMenuRecords : contextMenu"
	[style]="{'width': 'auto'}"></p-contextMenu>

<!-- Content -->
<div class="card" style="min-height: 100%">
	<p-table #table (onColResize)="onColResize($event)" (onColReorder)="onColReorder($event)"
		(onFilter)="onFilter($event)"
		(onRowSelect)="isMobile && cm.show($event.originalEvent); $event.originalEvent.stopPropagation();"
		[(contextMenuSelection)]="selectedEntry" [(selection)]="selectedEntry" [contextMenu]="cm"
		[globalFilterFields]="filters" [reorderableColumns]="true" [resizableColumns]="true"
		[rowTrackBy]="trackByFunction" [scrollHeight]="contentHeight + 'px'" [virtualRowHeight]="45"
		[virtualScroll]="false" columnResizeMode="expand" dataKey="ds_auto_id" scrollDirection="both"
		selectionMode="single" [stateKey]="stateName" stateStorage="local" styleClass="p-datatable-gridlines"
		[columns]="cols" [rows]="100" [scrollable]="true" [value]="entries">
		<ng-template pTemplate="caption">
			<div class="p-d-flex p-ai-center p-jc-between">
				<div>
					<button *ngIf="createPermission" type="button" pButton icon="fa fa-plus"
						class="p-button-success p-my-2 p-mr-3" pTooltip="{{'BUTTONS.NEW' | translate }}"
						tooltipPosition="bottom" (click)="create()"></button>
					<button type="button" pButton pRipple icon="fa fa-sitemap" class="p-my-2 p-mx-1"
						pTooltip="Automatisch gruppieren" tooltipPosition="bottom" (click)="groupTracks()"
						[disabled]="!entries"></button>
					<button type="button" pButton pRipple icon="fa fa-edit" class="p-my-2 p-ml-1 p-mr-3"
						pTooltip="{{!editGrouping ? 'Gruppierung bearbeiten' : 'Umgruppierung beenden'}}"
						tooltipPosition="bottom" (click)="editGrouping=!editGrouping"></button>
					<button type="button" pButton icon="pi pi-file-o" (click)="exportCSV()" class="p-my-2 p-mx-1"
						pTooltip="CSV" tooltipPosition="bottom"></button>
					<button type="button" pButton icon="pi pi-file-excel" (click)="exportXLSX()"
						class="p-button-success p-my-2 p-mx-1" pTooltip="XLS" tooltipPosition="bottom"></button>
					<button type="button" pButton icon="pi pi-file-pdf" (click)="exportPDF()"
						class="p-button-warning p-my-2 p-mx-1" pTooltip="PDF" tooltipPosition="bottom"></button>
					<button *ngIf="visyAdmin" type="button" pButton pRipple icon="fa fa-sitemap" class="p-my-2 p-mx-3"
						pTooltip="Dateitransfer in DB (Admin)" tooltipPosition="bottom" (click)="transferFiles()"
						[disabled]="!visyAdmin" [visible]="visyAdmin"></button>
					<p-inputNumber *ngIf="visyAdmin" [(ngModel)]="transferLimit" [min]="0"
						[max]="1024" [showButtons]="true" [disabled]="!visyAdmin">Limit</p-inputNumber>
				</div>
				<div *ngIf="!draggedFile">
					<h5 class="p-m-0">{{ name | translate }}</h5>
				</div>
				<div *ngIf="draggedFile" pDroppable="dd" (onDrop)="drop($event, null)"
					style="flex: 0.9;border: solid;align-self: stretch;display: flex;justify-content: center;align-items: center;">
					<h5 class="p-m-0">{{ draggedRecord ? 'Neue Akte für diese Aufzeichnung erstellen' : 'Aufzeichnungen
						aus Akte lösen'
						| translate }}</h5>
				</div>
				<span class="p-input-icon-left">
					<i class="pi pi-search"></i>
					<input pInputText id="global" type="text" [value]="globalFilter"
						(input)="table.filterGlobal($event.target.value, 'contains');globalFilter = $event.target.value;"
						placeholder="{{'BUTTONS.SEARCH'|translate}}" />
				</span>
			</div>
		</ng-template>
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th *ngFor="let col of columns" [style.flex]="'0 0 ' + col.width + 'px !important'"
					[pSortableColumn]="col.key" pResizableColumn pReorderableColumn>
					<div class="p-d-flex p-ai-center p-jc-between" style="min-width: 100%">
						<div>
							<p-sortIcon [field]="col.key"></p-sortIcon>
							{{ 'HEADERS.' + col.key | translate }}
						</div>
						<div *ngIf="col.type != 'rating'">
							<p-columnFilter [type]="col.type" [field]="col.key" display="menu" appendTo="body"
								[style.color]="(isColFiltered(col) ? 'var(--primary-color)' : 'var(--text-color)')">
							</p-columnFilter>
						</div>
						<div *ngIf="col.type == 'rating'">
							<p-columnFilter [field]="col.key" display="menu" appendTo="body" matchMode="in"
								[showMatchModes]="false" [showOperator]="false" [showAddButton]="false"
								[style.color]="(isColFiltered(col) ? 'var(--primary-color)' : 'var(--text-color)')">
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">
									<p-multiSelect [ngModel]="value" [options]="ratingOptions" placeholder="-"
										(onChange)="filter($event.value)" optionLabel="label" optionValue="value">
										<ng-template let-option pTemplate="selectedItems">
											<span *ngFor="let option of value" class="p-mr-1">
												<i [ngClass]="option === 0 ? 'pi pi-circle-off' : 'pi pi-circle-on'"
													[style.color]="ratings[option]"></i>
											</span>
											<div *ngIf="!value || value.length === 0">
												-
											</div>
										</ng-template>
										<ng-template let-option pTemplate="item">
											<i [ngClass]="option.value === 0 ? 'pi pi-circle-off' : 'pi pi-circle-on'"
												[style.color]="option.icon"></i>
										</ng-template>
									</p-multiSelect>
								</ng-template>
							</p-columnFilter>
						</div>
					</div>
				</th>
				<th id="buttoncolhead" [style.flex]="'0 0 ' + buttonColWidth + 'px'" alignFrozen="right" pFrozenColumn>
					<p-multiSelect #colselection [options]="possibleCols" [(ngModel)]="cols" dataKey="key"
						(onChange)="toggleColumn($event)" optionLabel="label" appendTo="body"
						[style.visibility]="'hidden'" [style.position]="'absolute'" [panelStyle]="{'min-width':'auto'}"
						class="colselect">
					</p-multiSelect>
					<button pButton pRipple icon="fas fa-table-columns"
						class="p-button-rounded p-button-secondary p-mr-2"
						(click)="colselection.show($event.originalEvent);" pTooltip="{{'BUTTONS.COL_SELECT'|translate}}"
						tooltipPosition="bottom" showDelay="500"></button>
					<button pButton pRipple icon="pi pi-filter-slash" class="p-button-rounded p-button-secondary p-mr-2"
						(click)="table.clear()" pTooltip="{{'BUTTONS.RESET_FILTERS'|translate}}"
						tooltipPosition="bottom" showDelay="500"></button>
					<button pButton pRipple icon="fas fa-table-cells" class="p-button-rounded p-button-secondary p-mr-2"
						(click)="resetTable()" pTooltip="{{'BUTTONS.RESET_TABLE'|translate}}" tooltipPosition="bottom"
						showDelay="500"></button>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-entry let-rowIndex="rowIndex" let-columns="columns" let-expanded="expanded">
			<tr style="height:45px;" [pContextMenuRow]="entry" [pSelectableRow]="entry" pDraggable="dd"
				(onDragStart)="dragStart($event, entry, aufzeichnung)" (onDragEnd)="dragEnd($event)" pDroppable="dd"
				(onDrop)="drop($event, entry)" dragHandle=".draggableIcon">
				<td *ngFor="let col of columns" [style.flex]="'0 0 ' + col.width + 'px !important'">
					<div *ngIf="columns.indexOf(col) == 0" class="p-m-2" style="display: contents">
						<div *ngIf="editGrouping">
							<i class="pi pi-bars draggableIcon"></i>
						</div>
						<button type="button" pButton pRipple [pRowToggler]="entry"
							(click)="toggleEntry(!expanded, entry)"
							class="p-button-text p-button-rounded p-button-plain"
							[icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
					</div>
					<div *ngIf="entry[col.key] === null">
						<i class="pi pi-minus"></i>
					</div>
					<div *ngIf="entry[col.key] !== null">
						<div *ngIf="col.type == 'text'">
							{{ entry[col.key] }}
						</div>
						<div *ngIf="col.type == 'numeric'">
							{{ entry[col.key] }}
						</div>
						<div *ngIf="col.type == 'boolean'">
							<i [ngClass]="entry[col.key] ? 'pi pi-check' : 'pi pi-times'"
								[style.color]="entry[col.key] ? 'green' : 'red'"></i>
						</div>
						<div *ngIf="col.type == 'date'">
							{{ entry[col.key].toLocaleString() }}
						</div>
						<div *ngIf="col.type == 'rating'">
							<i [ngClass]="entry[col.key] === 0 ? 'pi pi-circle-off' : 'pi pi-circle-on'"
								[style.color]="ratings[entry[col.key]]"></i>
						</div>
					</div>
				</td>
				<td class="buttoncolbody" [style.flex]="'0 0 ' + buttonColWidth + 'px'" alignFrozen="right"
					pFrozenColumn>
					<a id="detailbutton"
						[routerLink]="url_strecke + '/' + url_detail + '/' + entry['FREMD_strecke_ds_this_id']"
						class="p-button-rounded p-button-success p-mr-2 p-ripple p-button p-component p-button-icon-only">
						<i class="pi pi-search"></i>
					</a>
					<a *ngIf="updatePermission" id="editbutton"
						[routerLink]="url_strecke + '/' + url_edit + '/' + entry['FREMD_strecke_ds_this_id']"
						class="p-button-rounded p-button-warning p-mr-2 p-ripple p-button p-component p-button-icon-only">
						<i class="pi pi-pencil"></i>
					</a>
					<a *ngIf="deletePermission" id="deletebutton" (click)="delete(entry.ds_this_id)"
						class="p-button-rounded p-button-danger p-mr-2 p-ripple p-button p-component p-button-icon-only">
						<i class="pi pi-trash"></i>
					</a>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="rowexpansion" let-entry let-rowIndex="rowIndex" let-columns="columns">
			<tr *ngFor="let aufzeichnung of entry.aufzeichnungen" style="height:45px;" [pContextMenuRow]="aufzeichnung"
				pDraggable="dd" (onDragStart)="dragStart($event, entry, aufzeichnung)" (onDragEnd)="dragEnd($event)"
				pDroppable="dd" (onDrop)="drop($event, entry)" dragHandle=".draggableIcon">
				<td *ngFor="let col of columns" [style.flex]="'0 0 ' + col.width + 'px !important'" class="child-row">
					<div *ngIf="columns.indexOf(col) == 0 && editGrouping">
						<i class="pi pi-bars draggableIcon p-my-2 p-mx-4"></i>
					</div>
					<div *ngIf="aufzeichnung[col.key] === null">
						<i class="pi pi-minus"></i>
					</div>
					<div *ngIf="aufzeichnung[col.key] !== null">
						<div *ngIf="col.type == 'text'">
							{{ aufzeichnung[col.key] }}
						</div>
						<div *ngIf="col.type == 'numeric'">
							{{ aufzeichnung[col.key] }}
						</div>
						<div *ngIf="col.type == 'boolean'">
							<i [ngClass]="aufzeichnung[col.key] ? 'pi pi-check' : 'pi pi-times'"
								[style.color]="aufzeichnung[col.key] ? 'green' : 'red'"></i>
						</div>
						<div *ngIf="col.type == 'date'">
							{{ aufzeichnung[col.key].toLocaleString() }}
						</div>
					</div>
				</td>
				<td class="buttoncolbody p-p-0" [style.flex]="'0 0 ' + buttonColWidth + 'px'" alignFrozen="right"
					pFrozenColumn>
					<div class="child-row p-m-0 p-p-3">
						<a id="detailbutton"
							[routerLink]="url_aufzeichnung + '/' + url_detail + '/' + aufzeichnung.ds_this_id"
							class="p-button-rounded p-button-success p-mr-2 p-ripple p-button p-component p-button-icon-only">
							<i class="pi pi-search"></i>
						</a>
						<a *ngIf="updatePermission"
							[routerLink]="url_aufzeichnung + '/' + url_edit + '/' + aufzeichnung.ds_this_id"
							class="p-button-rounded p-button-warning p-mr-2 p-ripple p-button p-component p-button-icon-only">
							<i class="pi pi-pencil"></i>
						</a>
						<a *ngIf="deletePermission" (click)="deleteRecord(aufzeichnung.ds_this_id)"
							class="p-button-rounded p-button-danger p-mr-2 p-ripple p-button p-component p-button-icon-only">
							<i class="pi pi-trash"></i>
						</a>
					</div>
				</td>
			</tr>
			<tr *ngIf="loadingRecords !== entry.ds_this_id && (!entry.aufzeichnungen || entry.aufzeichnungen.length == 0)"
				style="height:45px;">
				<td class="child-row" style="flex: auto !important">
					Keine Einträge vorhanden
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="summary">
			<div class="p-d-flex p-ai-center p-jc-between">
				{{ 'TABLE.COUNT' | translate : { count : count } }}
				{{
				(table && table.filteredValue ? 'TABLE.TOTAL' : '') | translate : { count : (entries ? entries.length
				: 0) }
				}}
				<div>
					{{ 'TABLE.LAST_REFRESH' | translate }}: {{ loadTimestamp ? loadTimestamp.toLocaleString() : '' }}
				</div>
			</div>
		</ng-template>
	</p-table>
</div>